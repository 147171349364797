@import './theme';

body {
	background-color: $blue;
	margin: 0;
	padding: 0;
	font-family: Verdana, Arial, sans-serif;
	font-size: 14px;
	color: $darkGray;
}

.flex, .flexr {
	display: flex;
	flex-wrap: wrap;

	.flex1 {
		flex: 1;
	}

	.flex2 {
		flex: 2;
	}
	
	.flex3 {
		flex: 3;
	}
	
	.flex4 {
		flex: 4;
	}
	
	.flex5 {
		flex: 5;
	}
	
	.flex6 {
		flex: 6;
	}
}

.flexr {
	flex-wrap: wrap-reverse;
}