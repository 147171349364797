/* Color Palette Theme */

$darkGray: #333333;
$lightGray: #eeeeee;
$blue: #046380;
$mediumBlue: #338399;
$lightBlue: #b7d0d8;
$white: #fff;
$warn: red;
$primary: green;
$darkGreen: #688778;
$darkOrange: #d66e47;
$lightOrange: #e79c57;
$yellow: #f7cd6d;
$chocolate: #6c5051;
