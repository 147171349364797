@import '../scss/theme';

.recommendation {
  margin-bottom: 16px;
  line-height: 20px;

  div.quote {
    margin-left: 32px;
    max-width: 650px;

    .actualQuote {
        display: flex;

        div.quoteText {
            flex: 1;
        }

        div.icon {
            width: 32px;
            margin-top: 14px;

            svg.fa-quote-left {
                margin-left: -24px;
                margin-top: -2px; 
                font-size: 1.3em;
                color: $blue;
                float: left;
            }
            svg.fa-quote-right {
                margin-left: 6px;
                display: inline;
                color: $blue;
                float: none;
            }
        }
    }
  }

  div.signature {
    color: $blue;
    text-align: right;
    font-weight: bold;
    display: block;
    white-space: nowrap;
  }
}

.linkedIn {
    margin-top: 16px;
    text-align: center;

    .linkedInA {
        display: inline-block;
        min-width: 50px;
        margin: 2px auto;
    }
}

@media only screen and (max-width: 414px) {
  .recommendation div.quote {
    width: 275px;
  }
}