@import '../scss/theme.scss';

.container .containerRight .inline-skill {
    margin-bottom: 4px;
    margin-left: auto;
    margin-right: auto;
    max-width: 550px;
    text-align: center;
    
    .skillButton {
        display: inline-block;
        width: auto;
        margin: 1px 2px;
        padding: 4px;
        color: #046380;
        border: 1px solid #b7d0d8;
        border-radius: 5px;
        display: inline-block;
        font-size: 0.8em;
        cursor: normal;

        &.selected {
            background-color: #b7d0d8;
        }
    }
}

.container .containerLeft .leftPanelButton.skillButton {
    display: inline-block;
    width: auto;
    margin: 2px;

    &.selected {
        background-color: #046380;
        color: #b7d0d8;
    }
}

.certification {
    margin-bottom: 6px;
}

.volunteer {
    .company-header {
        font-weight: bold;
        color: $blue;
    }
    ul {
        margin-top: 4px;
        margin-left: -15px;
    }
}
.print-icon {
	max-width: 30px;
	margin-left: 16px;
	display: inline-block;
	
	a {
		color: $blue;
		text-decoration: none;
	}
}

.experience {
    margin-bottom: 16px;
    .exp-header {
        font-weight: bold;
        color: $blue;
    }
      
    .date {
        min-width: 200px;
        float: right;
        text-align: right;
    }
      
    .title {
        margin-left: 8px;
        font-style: italic;
    }
    
    .description {
        margin-left: 8px;
        line-height: 20px;
    }
      
    .projects {
        margin-top: 8px;
        margin-left: 8px;

        strong {
            font-weight: normal;
            color: $blue;
        }
      
        ul {
          margin-top: 4px;
          margin-bottom: 6px;
          list-style-type: square;
          
          li {
            padding-left: 0;
            line-height: 20px;
          }
        }
      }
}