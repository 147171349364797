
@import '../../scss/theme.scss';

.header {
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    width: 100%;
    background-color: $chocolate;
    border-bottom: 3px solid $darkOrange;
    color: $white;
    display: flex;
    padding-left: 8px;
  
    .wordmark {
      font-size: 2.5em;
      flex: 2;
      margin: auto auto;
    }
  
    .headerMenu {
      flex: 3;
      height: 39px;
      margin-top: 51px;
      
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
  
        li {
          min-width: 50px;
          float: left;
          margin-right: 8px;
  
          a {
            display: inline-block;
            text-decoration: none;
            color: #fff;
            font-size: 1.1em;
            background-color: $chocolate;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 4px 8px;
            cursor: pointer;
          }
  
          a.selected {
            border: 2px solid $darkOrange;
            background-color: $blue;
            border-bottom: 3px solid $blue;
            margin-top: 0px;
            padding: 3px 8px 6px 8px;
          }
        }
      }
    }
  }

@media only screen and (min-width: 846px) {
    #standardMenu {
        display: block;
    }
    #mobileMenu {
        display: none;
    }
}
@media only screen and (max-width: 845px) {
  .header {
    height: 60px;
    .wordmark {
      flex: 4;
      font-size: 1.2em;
    }

  }

    #standardMenu {
        display: none;
    }
    #mobileMenu {
        display: block;
        flex: 1;
    }
}