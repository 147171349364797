@import './theme';
.container {
  max-width: 960px;
  width: 100%;
  display: flex;
  margin: 8px auto;

  .containerFull, .containerLeft, .containerRight {
      padding: 8px 16px 32px 16px;
      background-color: $white;
      border-radius: 5px;

      .resumeSubHeader {
          min-width: 150px;
          float: right;
          text-align: right;
          font-size: 0.5em;
          font-weight: normal;
      }
      .email a {
          font-weight: bold;
          text-decoration: none;
          color: $darkGray;
      }

      .conversationalSpeech {
          padding: 0 8px 8px;
          max-width: 800px;
          margin-left: 16px;
          line-height: 20px;
      }

      h1 {
          border-bottom: 1px solid $blue;
          color: $blue;
          font-size: 2em;
          font-weight: bold;
      }

      .notifierPanel {
          padding: 8px;
          color: $blue;
          background-color: $lightBlue;
          border: 1px solid $lightBlue;
          border-radius: 5px;
          margin-bottom: 24px;
          margin-top: 8px;

          .closeX {
              float: right;
              background-color: transparent;
              border: 0;
              font-size: 1.1em;
              color: $blue;
              cursor: pointer;
          }

          h2 {
              border-bottom: 1px solid $blue;
              margin-top: 0;
          }

          .instructions {
              font-size: 0.8em;
              text-align: center;
          }
      }
  }

  .containerFull {
      flex: 1;
      max-width: 928px;
      width: 100%;
      margin: 8px auto;
  }

  .containerLeft {
      margin-top: 64px;
      max-width: 178px;

      .leftPanelButton {
          padding: 8px;
          color: $blue;
          background-color: $lightBlue;
          border: 1px solid $lightBlue;
          border-radius: 5px;
          display: inline-block;
          margin: 2px auto;
          width: 178px;
          cursor: pointer;
      }
  }

  .containerRight {
      flex: 5;
      margin-top: 8px;
      margin-left: 8px;
  }

}

@media only screen and (max-width: 640px) {
  .container {
      width: 94%;
      max-width: 94%;
      margin-left: 4%;

      flex-direction: column-reverse;;
      flex-wrap: wrap-reverse;

      .containerLeft {
          margin-top: 8px;
          width: 96%;
          max-width: 96%;
      }
  }
}